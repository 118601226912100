<template>
	<div class="body-bg">
		<el-affix >
			<div id="title"><span id="back" @click="onHome()" style="vertical-align: top;margin-top: -2px;margin-left: 10px;">‹</span><div style="margin-right: 20px;">{{ title }}</div></div>
		</el-affix>

		<div id="search">
		 <el-form :inline="true" class="demo-form-inline" @submit.prevent='Search' >
			<el-form-item class="elInput" style="width: 100%;">
			  <el-input v-model="keyword" placeholder="搜索" :prefix-icon="Search" @keyup.enter="onDefault(0, 0, 1)"></el-input>
			</el-form-item>
		  </el-form>
		</div>
		<div id="Busicontent"  style="overflow-y:hidden">
			  <div  v-infinite-scroll="load" infinite-scroll-distance="10" infinite-scroll-immediate="true" style="overflow:auto">
			    <div :span="24" id='clear' v-for="item in tableData" :key="item.index"  @click="onContent(item.category_id, item.id, item.outer, item.outer_url)">
            <div ><img :src="item.avatar" style=" width: 100px; height: 80px; border-radius: 10px;"   alt=""></div>
					<div class="contentTitle">{{ item.title }}</div>
					<div class="contentBrief">{{ item.brief }}</div>
				</div>
			  </div>
			<div class="loading" v-if="loading">
			  <span id="load-text">{{loadText}}</span>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {defineComponent, onMounted, ref, watch, reactive} from 'vue'
	import * as api from "../api/loginHttp";
	import {ElMessage, ElNotification} from "element-plus";
	import { Calendar, Search } from '@element-plus/icons-vue'
	import {useRoute, useRouter} from "vue-router"
	import http, {baseUrl} from "../utils/http";
	const route = useRoute()
	const router = useRouter()
	const cateid = ref()
	const formInline  = reactive({
		user: '',
		region: '',
	})
	const beforeCreate = async () => {
		document.querySelector('body').setAttribute('style', 'background-color:#EFEFEF;')
	}
	const title = ref()
	const keyword = ref()
	const contents = ref({})
	const loadText = ref('正在加载...')
	const loading  = ref(false)
	const tableData = ref([])
	const pages = ref(0);
	const limit = ref(20);
	const totalPages = ref();

	const fetchData = async () => {
		cateid.value = route.params.cateid
		http.get('api/news/getCateTitle/cateid' + "/" + cateid.value).then((res)=>{
			if(res.data.code == 200) {
				title.value = res.data.data
			} else {
				ElNotification({
				    title: 'Error',
				    message: '获取数据错误',
				    type: 'error',
				  })
				return false;
			}
		})
	}
	const onHome = async () =>{
		await router.push({'path':'/Home/'})
	}

	const onDefault = async (isReset, addTo = 0, Botton = 0) => {
	if(isReset == 1) {
		keyword.value = '';
	  }

	  if(Botton == 1 ){
		pages.value = 0;
		tableData.value =  [];
	  }

	  let params = {
		'cateid': cateid.value,
		'pages': pages.value,
		'limit': limit.value,
	  }

	  if (keyword.value != "") {
		params.keyword = keyword.value
	  }

	  const data = await api.getNewList(params);
	  let len = data.data.data.contents.length
	  if (data && len == 0 && !len) {
	    loadText.value = '暂无更多数据'
		loading.value = true;
	  } else if (data && len < 20) {
	    loadText.value = '暂无更多数据'
		loading.value = true;
	  } else {
	    loading.value = false
	  }
	  if(len > 0) {
		  pages.value = pages.value + 20; //页数+10
	  }
	  tableData.value = tableData.value.concat(data.data.data.contents); //因为每次后端返回的都是数组，所以这边把数组拼接到一起
	  totalPages.value = data.data.data.totalPages;
	}

	const onContent = async (cateid, ids, outer, outer_url) => {
		if(outer == 1) {
			if(cateid == 2){
				http.get('api/news/contents/id' + "/" + ids).then((res)=>{
			        if(res.data.code == 200) {
						window.location = outer_url;
			        } else {
				        ElNotification({
				            title: 'Error',
				            message: '获取数据错误',
				            type: 'error',
				        })
				        return false;
			        }
		        })
			}
			else if(cateid == 4){
				location.href = outer_url
			}
		} else {
			await router.push({'path': '/Contents/' + cateid +'/' + ids})
		}
	}

	const load = () => {
		if(loading.value == false) {
			setTimeout(() => {
			  //滑到底部时进行加载
			  //loading.value = true
			   onDefault(0, 1)
			}, 1000)
		}
	}


	//自动加载
	onMounted(() => {
		fetchData()
		beforeCreate()
		const appElement = document.getElementById('app');
		if (appElement) {
		  appElement.style.background = '#eaf0fd';
		}
		document.querySelector('html').setAttribute('style', 'background: #eaf0fd')
	})

</script>
<style scoped>
	#clear {
		clear:both;
		padding-left: 10px;
	}
	#search{
		width: 100%;
		margin-left: 0;
		margin-top: 2%;
		border-radius: 20px;
	}
	::v-deep .el-input__inner{
		border-radius: 20px;
	}
	.el-form-item{
		margin-bottom: 0;
	}
	.body-bg {
		/* background: #EFEFEF; */
		min-height: 720px;
		box-sizing: border-box;
		margin: 0 1%;
	}
	#title {
		width: 100%;
		height: 45px;
		font-size: 20px;
		text-align: center;
		line-height: 45px;
		color: #4D4D4D;
		background-color: #eaf0fd;
		/* border-bottom:1px solid white; */
	}
	#Busicontent{
		width: 100%;
	}
	#Busicontent #clear{
		width: 100%;
		list-style-type:none;
		min-height: 45px;
		display: flex;
		/* align-items: center; */
		border-radius: 8px;
		box-sizing: border-box;
		padding: 2%;
		margin-top: 2%;
		background-color: rgba(255, 255, 255, 0.6);
	}
  #Busicontent #clear .contentTitle {
    display: inline-block;
    font-size: 16px;
    color: #333333;
    padding-left: 10px;
  }
  #Busicontent  .contentBrief {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 10px;
    color: #B9B9B9;
    /*height: 40px;*/
    padding-left: 10px;
  }
	.loading {
		width: 100%;
		height: 50px;
		line-height: 50px;
		text-align: center;
	}

</style>
