<template>
  <div
    class="floating-ball"
    :style="{ top: ballPosition.y + 'px', left: ballPosition.x + 'px' }"
    @mousedown="startDrag"
    @touchstart="startDrag"
    @click="toggleView"
  >
   <el-link :underline="false" href="#/big/home" style="color: white; font-weight: 600;" v-if="textNormal">大字版</el-link>
   <el-link :underline="false" href="#/" style="color: white; font-weight: 600;" v-if="textLarge">标准版</el-link>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const props = defineProps({
  textNormal: Boolean,
  textLarge: Boolean,
});

const ballPosition = ref({ x: 0, y: 170 }); // 初始位置
const dragging = ref(false);
const startX = ref(0);
const startY = ref(0);
const initialX = ref(0);
const initialY = ref(0);

const startDrag = (event) => {
  dragging.value = true;
  if (event.type.startsWith('mouse')) {
    startX.value = event.clientX - ballPosition.value.x;
    startY.value = event.clientY - ballPosition.value.y;
    document.addEventListener('mousemove', drag);
    document.addEventListener('mouseup', stopDrag);
  } else if (event.type.startsWith('touch')) {
    const touch = event.touches[0];
    startX.value = touch.clientX - ballPosition.value.x;
    startY.value = touch.clientY - ballPosition.value.y;
    document.addEventListener('touchmove', drag);
    document.addEventListener('touchend', stopDrag);
  }
};

const drag = (event) => {
  if (!dragging.value) return;
  let newX, newY;
  if (event.type.startsWith('mouse')) {
    newX = event.clientX - startX.value;
    newY = event.clientY - startY.value;
  } else if (event.type.startsWith('touch')) {
    const touch = event.touches[0];
    newX = touch.clientX - startX.value;
    newY = touch.clientY - startY.value;
  }
 
  // 获取屏幕边界
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  const ballWidth = 50; // 悬浮球宽度
  const ballHeight = 50; // 悬浮球高度，根据CSS中的定义
 
  // 计算调整后的位置
  let adjustedX = newX;
  let adjustedY = newY;
 
  if (adjustedX < 0) adjustedX = 0; // 左边界
  if (adjustedX + ballWidth > screenWidth) adjustedX = screenWidth - ballWidth; // 右边界
  if (adjustedY < 0) adjustedY = 0; // 上边界
  if (adjustedY + ballHeight > screenHeight) adjustedY = screenHeight - ballHeight; // 下边界
 
  // 更新悬浮球位置
  ballPosition.value.x = adjustedX;
  ballPosition.value.y = adjustedY;
};

const stopDrag = () => {
  dragging.value = false;
  document.removeEventListener('mousemove', drag);
  document.removeEventListener('mouseup', stopDrag);
  document.removeEventListener('touchmove', drag);
  document.removeEventListener('touchend', stopDrag);
};
// 组件挂载时，确保悬浮球不会超出视口边界
onMounted(() => {
  // 获取屏幕宽度
  const screenWidth = window.innerWidth;
  // 计算悬浮球的初始 x 位置（屏幕宽度 - 悬浮球宽度）
  ballPosition.value.x = screenWidth - 70;
 
  // 调整位置的函数（现在不需要用于设置初始位置，但可以在其他场景中使用）
  const adjustPosition = () => {
    const rect = document.querySelector('.floating-ball').getBoundingClientRect();
    const windowHeight = window.innerHeight;
 
    if (rect.left < 0) ballPosition.value.x = 0;
    if (rect.right > screenWidth) ballPosition.value.x = screenWidth - rect.width;
    if (rect.top < 0) ballPosition.value.y = 0;
    if (rect.bottom > windowHeight) ballPosition.value.y = windowHeight - rect.height;
  };
});
onUnmounted(() => {
  stopDrag();
});
</script>

<style scoped>
.floating-ball {
  position: fixed;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  z-index: 1000; /* 确保悬浮球在其他内容之上 */
  background:linear-gradient(-45deg, #fad0c4, #fad0c4, #a1c4fd, #f2f0a1, #c2e9fb);
  background-size: 200% 200%;
  animation: gradient 8s ease infinite;
  opacity: 0.8;
}
@keyframes gradient {

		0%,
		100% {
			background-position: 0 0;
		}

		25% {
			background-position: 100% 0;
		}

		50% {
			background-position: 100% 100%;
		}

		75% {
			background-position: 0 100%;
		}
	}
</style>