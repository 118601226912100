<template>
	<div class="body-bg">
		<el-affix >
			<div id="title"><span id="back" @click="onHome()" style="vertical-align: top;margin-top: -2px;margin-left: 10px;">‹</span><div style="margin-right: 20px;">{{ title }}</div></div>
		</el-affix>
		<div id="search">
		 <el-form :inline="true" class="demo-form-inline"  @submit.prevent='Search' >
			<el-form-item class="elInput" style="width: 100%;">
			  <el-input v-model="keyword" placeholder="搜索" :prefix-icon="Search" @keyup.enter.native="onDefault(0, 0, 1)"></el-input>
			</el-form-item>
		  </el-form>
		</div>
		<div id="content"  style="overflow-y:hidden">
			  <div  v-infinite-scroll="load" infinite-scroll-distance="10" infinite-scroll-immediate="true" style="overflow:auto">
				<div :span="24" id='clear' v-for="item in tableData" :key="item.index">
					<span class="contentTitle"  @click="onContent(item.category_id, item.id)"> {{ item.title }}</span>
					<span  @click="onContent(item.category_id, item.id)"><img src="../assets/img/download2.png" alt="" style="width: 25px; margin-top: 3%;"></span>
				</div>
			 </div>
			<div class="loading" v-if="loading">
			  <span id="load-text">{{loadText}}</span>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {defineComponent, onMounted, ref, watch, reactive} from 'vue'
	import * as api from "../api/loginHttp";
	import {ElMessage, ElNotification} from "element-plus";	
	import { Calendar, Search } from '@element-plus/icons-vue'
	import {useRoute, useRouter} from "vue-router"
	import http, {baseUrl} from "../utils/http";
	const route = useRoute()
	const router = useRouter()
	const cateid = ref()
	const formInline  = reactive({
		user: '',
		region: '',
	})
	const beforeCreate = async () => {
		document.querySelector('body').setAttribute('style', 'background-color:#EFEFEF;')
	}
	const title = ref()
	const keyword = ref()
	const contents = ref({})
	const loadText = ref('正在加载...')
	const tableData = ref([])
	const loading = ref(false)
	const pages = ref(0);
	const limit = ref(20);
	const totalPages = ref();
	
	
	const fetchData = async () => {
		cateid.value = route.params.cateid
		http.get('api/news/getCateTitle/cateid' + "/" + cateid.value).then((res)=>{
			if(res.data.code == 200) {
				title.value = res.data.data
			} else {
				ElNotification({
				    title: 'Error',
				    message: '获取数据错误',
				    type: 'error',
				  })
				return false;
			}
		})
	}
	const onHome = async () =>{
		await router.push({'path':'/big/Home/'})
	}
	
	const onDefault = async (isReset, addTo = 0, Botton = 0) => {
		// return;	
	  if(Botton == 1 ){
	    pages.value = 0;
		tableData.value =  [];
	  }
	  
	  // return false;
	
	  let params = {
	    'cateid': cateid.value,
	    'pages': pages.value,
	    'limit': limit.value,
	  }
	
	  if (keyword.value != "") {
	    params.keyword = keyword.value
	  }
	  const data = await api.getNewList(params);
	  
	  
	  let len = data.data.data.contents.length
	  if (data && len == 0 && !len) {
	    loadText.value = '暂无更多数据'
		loading.value = true;
	  } else if (data && len < 20) {
	    loadText.value = '暂无更多数据'
		loading.value = true;
	  } else {
	    loading.value = false
	  }
	  if(len > 0) {
	  		pages.value = pages.value + 20; //页数+10
	  }
	  tableData.value = tableData.value.concat(data.data.data.contents); //因为每次后端返回的都是数组，所以这边把数组拼接到一起
	  totalPages.value = data.data.data.totalPages;
	}
	
	const onContent = async (cateid, ids) => {
	  await router.push({'path': '/big/Contents/' + cateid +'/' + ids})
	}
	
	const load = async () => {
		if(loading.value == false) {
			setTimeout(() => {
			  //滑到底部时进行加载
			  //loading.value = true
			   onDefault(0, 1)
			}, 1000)
		}
		
	}
	
	
	
	
	//自动加载
	onMounted(() => {
	  fetchData()
	  beforeCreate()
	  const appElement = document.getElementById('app');
	  if (appElement) {
	    appElement.style.background = '#eaf0fd';
	  }
	  document.querySelector('html').setAttribute('style', 'background: #eaf0fd')
	})
	
</script>
<style scoped>
	#clear {
		clear:both;
		padding-left: 10px;
	}
	#search{
		width: 100%;
		margin-left: 0;
		margin-top: 2%;
		border-radius: 20px;
	}
	::v-deep .el-input__inner{
		border-radius: 20px;
	}
	.el-form-item{
		margin-bottom: 0;
	}
	#content{
		width: 100%;
	}
	#content #clear{
		width: 100%;
		list-style-type:none;
		min-height: 45px;
		display: flex;
		align-items: center;
		background: #fff;
		border-radius: 8px;
		padding: 0px;
		margin-top: 2%;
		background-color: rgba(255, 255, 255, 0.6);
	}
	#content #clear .contentTitle {
		display: inline-block; 
		width: 86%;		
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding-left: 2%;
		font-size:19px ;
	}
	 .body-bg {
	 	/* background: #EFEFEF; */
	 	min-height: 720px;
	 	box-sizing: border-box;
	 	margin: 0 1%;
	 }
	#title {
		width: 100%;
		height: 45px;
		font-size: 20px;
		text-align: center;
		line-height: 45px;
		color: #4D4D4D;
		background-color: #eaf0fd;
	}
	#back {
		float: left;
		font-size: 30px;
	}
	.loading {
		width: 100%;
		height: 50px;
		line-height: 50px;
		text-align: center;
	}
	
</style>
