<template>
		<!-- <div class="body-bg"> -->
			<el-affix >
				<div id="title"><span id="back" @click="onHome()" style="vertical-align: top;margin-top: -2px;margin-left: 10px;">‹</span><div style="margin-right: 20px;">{{ title }}</div></div>
			</el-affix>
			<div id="subTitle">{{contentsTitle}}</div>
			<div class="card">
				<div id="contents"  v-html="contentsValue"></div>
			</div>
		<!-- </div> -->
</template>

<script setup>
	import {defineComponent, onMounted, ref, watch, reactive} from 'vue'
	import * as api from "../api/loginHttp";
	import {ElMessage, ElNotification} from "element-plus";	
	import { Calendar, Search } from '@element-plus/icons-vue'
	import {useRoute, useRouter} from "vue-router"
	import http, {baseUrl} from "../utils/http";
	const route = useRoute()
	const router = useRouter()
	const cateid = ref()
	const title = ref()
	const id = ref()
	const contentsTitle = ref()
	const contentsValue = ref()
	const fetchData = async () => {
		cateid.value = route.params.cateid
		id.value = route.params.id
		http.get('api/news/getCateTitle/cateid' + "/" + cateid.value).then((res)=>{
			if(res.data.code == 200) {
				title.value = res.data.data
			} else {
				ElNotification({
				    title: 'Error',
				    message: '获取数据错误',
				    type: 'error',
				  })
				return false;
			}
		})
		http.get('api/news/contents/id' + "/" + id.value).then((res)=>{
		
			if(res.data.code == 200) {
				contentsTitle.value = res.data.data.title
				contentsValue.value = res.data.data.contents
			} else {
				ElNotification({
				    title: 'Error',
				    message: '获取数据错误',
				    type: 'error',
				  })
				return false;
			}
		
		})
	}
	
	const beforeCreate = async () => {
		document.querySelector('body').setAttribute('style', 'background-color:#fff;')
	}
	const onHome = async () =>{
		if(cateid.value == 1) {
			await router.push({'path':'/big/Download/'+ cateid.value})
		} else {
			await router.push({'path':'/big/Business/'+ cateid.value})
		}
		
	}
	//自动加载
	onMounted(() => {
	  fetchData()
	  beforeCreate()
	 const appElement = document.getElementById('app');
	 if (appElement) {
	   appElement.style.background = '#eaf0fd';
	 }
	 document.querySelector('html').setAttribute('style', 'background: #eaf0fd')
	})
</script>
<style>
	#contents a{
		/* text-decoration: none; */
		color: cornflowerblue;
		font-size: 25px;
	}
</style>
<style scoped>
	.body-bg {
	    /* background: #EFEFEF; */
	    min-height: 720px;
	}
	#title {
		width: 100%;
		height: 45px;
		font-size: 20px;
		text-align: center;
		line-height: 45px;
		color: #4D4D4D;
		background-color: #eaf0fd;
	}
	#subTitle {
		color:#000;
		text-align: left;
		min-height: 50px;
		line-height: 30px;
		font-size: 22px;
		background:none ;
		padding-left: 2%;
		padding-top: 2%;
	}
	#back {
		float: left;
		font-size: 30px;
	}
	.card{
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 1%;
	}
	#contents {
		width:93%;
		border-radius: 10px;
		padding: 2%;
		background-color: rgba(255, 255, 255, 0.6);
	}
	#contents img{
		width: 100%;
	}
</style>
