<template>
  <!-- <el-row class="top-bg">
    <el-col :span="24">
      <el-link type="primary" href="#/big/home" style="color: blue;font-size: 20px;">切换大字版</el-link>
    </el-col>
  </el-row> -->

  <el-row style="border-radius: 10px; margin-top: 2%;" >
	  <el-col :span="24">
		  <LinkToLarge :textNormal="textNormal" :textLarge="textLarge" ></LinkToLarge>
	  </el-col>
  </el-row>
  <el-row style="padding-top: 1%;" >
	  <el-col :span="24">
		 <div class="top">
		 	<div style="width: 100%;">
		 		<div class="title">
					<div><img src="../assets/img/Logo1.png" style="width: 75px;"/></div>
					<div>大兴医保"心"服务微平台</div>
				</div>
		 		<span><img src="../assets/img/duigou.png" alt="" />更便捷<img src="../assets/img/duigou.png" alt="" />更轻松</span>
		 	</div>
		 </div> 
	  </el-col>
  </el-row>
  <el-row >
	<el-col :span="24">
		<div class="block" style="overflow: overlay; border-radius: 8px;">
		  <v-swiper :data="banners" v-if="banners.length"/>
		</div>
	</el-col>
    <div class="nav">
      <div id="Follow">
		<img src="../assets/img/handle.png" style="width: 20px; margin-right: 2%;" alt="" />
        <span class="followDian">省心办</span> <span style="color:#999;font-size: 14px;"> | 办事更便捷</span>
      </div>
      <div class="grid">
		  <div class="type" @click="onDownload(1)">
		    <div><img src="../assets/image/Cbgxz.png" alt=""></div>
		    <div class="navTitle">表格下载</div>
		  </div>
		  <div class="type" @click="onBusiness(2)">
		    <div><img src="../assets/image/Bywzn.png" alt=""></div>
		    <div class="navTitle">业务指南</div>
		  </div>
		  <div class="type" @click="onBusiness(3)">
		    <div><img src="../assets/image/Ayybl.png" alt=""></div>
		    <div class="navTitle">预约办理</div>
		  </div>
		  <div class="type" @click="onExpress()" style="float: left">
		    <div><img src="../assets/image/icon-express.png" alt="" width="30px"></div>
		    <div class="navTitle">掌上邮寄</div>
		  </div>
	  </div>
    </div>

    <div class="nav Sxin">
      <div id="Follow">
		<img src="../assets/img/inquiry.png" style="width: 25px; margin-right: 2%;" alt="" />
        <span class="followDian">随心查</span> <span style="color:#999;font-size: 14px;"> | 查询更轻松</span>
      </div>
		<!-- <span>❤</span> -->
      <div class="grid">
		  <div class="type" @click="onBusiness(5)" style="width: 33%;">
		    <div><img src="../assets/image/Fddlsy.png" alt=""></div>
		    <div class="navTitle">个人医保<br />就医查询</div>
		  </div>
		  <div class="type" @click="onBusiness(6)" style="width: 33%;">
		    <div><img src="../assets/image/Eddyljg.png" alt=""></div>
		    <div class="navTitle">定点医院<br />药店查询</div>
		  </div>
		  <div class="type" @click="onBusiness(4)" style="width: 33%;">
		    <div><img src="../assets/image/Dybcz.png"  alt=""></div>
		    <div class="navTitle">医保须知</div>
		  </div>
		  <div class="type" @click="onTreatment(6)" style="width: 33%;">
		    <div><img src="../assets/image/Isbjbjg.png" alt=""></div>
		    <div class="navTitle">大兴社保<br />经办机构查询</div>
		  </div>
		  <div class="type" @click="onTreatment(5)" style="width: 33%;">
		    <div><img src="../assets/image/Halddyyjg.png" alt=""></div>
		    <div class="navTitle"> A类定点<br />医疗机构查询</div>
		  </div>
		  <div class="type" @click="onBusiness(7)" style="width: 33%;">
		    <div><img src="../assets/image/Gypml.png"  alt=""></div>
		    <div class="navTitle">药品目录查询</div>
		  </div>
	  </div>
    </div>
    <div id="clear"></div>

    <div class="nav Zxin">
      <div id="Follow">
		<img src="../assets/img/ask.png" style="width: 20px; margin-right: 2%;" alt="" />
        <span class="followDian">知心答</span> <span style="color:#999;font-size: 14px;"></span>
      </div>
      <div class="box-container">
        <div class="box" @click="onCust(7)"><img src="../assets/img/zhineng.jpg" class="box-image"></div>
        <div class="box" @click="onBusiness(8)"><img src="../assets/img/rengong.png" class="box-image"></div>
      </div>
    </div>
  </el-row>
</template>
<script setup>
// import 'swiper/css';
import {defineComponent, onMounted, ref, watch, reactive} from 'vue'
import * as api from "../api/loginHttp";
import {ElMessage, ElNotification, ElMessageBox, ElLoading} from "element-plus";
import {Action} from 'element-plus';
import http, {baseUrl} from "../utils/http";
import {useRoute, useRouter} from "vue-router"
import VSwiper from "../components/swiper";
import LinkToLarge from '../components/LinkToLarge.vue';

const route = useRoute()
const router = useRouter()


const Default = reactive({
  relation: [
    '../assets/img/banner.png'
  ],
})
const textNormal = ref(true)
const textLarge = ref(false)
const background = ref('#ffffff'); // 初始化为白色
const topImg = ref()
const activeName = ref('first')
const title = ref()
const contents = ref({})
const autoplay = ref(true)
const banners = ref([])
const yybl_url = ref()
const ywzn_url = ref()
const ddyljgcx_url = ref()
const ddlsydcx_url = ref()
const ypmlcx_url = ref()
const express_url = ref()

const popup_title = ref()
const popup_content = ref()
const popup_status = ref()
const load = async () => {
  //滑到底部时进行加载
  loading.value = true;
  pages.value += 20; //页数+10
  fetchData(0, 1); //调用接口，此时页数+1，查询下一页数据
}
const beforeCreate = async () => {
  document.querySelector('body').setAttribute('style', 'background-color:#fff;')
  document.querySelector('html').setAttribute('style', 'background: linear-gradient(to bottom, #b1d3fd, #dce7fd, #ffffff );')
}

const fetchData = async () => {

  http.get('api/news/gettitle').then((res) => {
    if (res.data.code == 200) {
      title.value = res.data.data
    } else {
      ElNotification({
        title: 'Error',
        message: '获取数据错误',
        type: 'error',
      })
      return false;
    }
  })
  http.get('api/news/index').then((res) => {
    if (res.data.code == 200) {
      topImg.value = res.data.data.banner[0].avatar;
      banners.value = res.data.data.banner;
      // contents.value = res.data.data
      // console.log(res.data.data);
      yybl_url.value = res.data.data.yybl_url
      ywzn_url.value = res.data.data.ywzn_url
      ypmlcx_url.value = res.data.data.ypmlcx_url
      ddyljgcx_url.value = res.data.data.ddyljgcx_url
      ddlsydcx_url.value = res.data.data.ddlsydcx_url
      express_url.value = res.data.data.express_url

      popup_title.value = res.data.data.popup_title
      popup_content.value = res.data.data.popup_content
      popup_status.value = res.data.data.popup_status

      if (popup_status.value == 1 && window.localStorage.getItem('ElMessages') != 1) {
        ElMessageBox.alert(popup_content.value, popup_title.value, {
          confirmButtonText: '确定',
          customStyle: 'width:80%',
          callback: () => {
          },
        })
      }
      window.localStorage.setItem("ElMessages", 1);
    } else {
      ElNotification({
        title: 'Error',
        message: '获取数据错误',
        type: 'error',
      })
      return false;
    }
  })
}
const onBusiness = async (ids) => {
  if (ids == 3) {
    window.location = yybl_url.value;
  } else if (ids == 2) {
    await router.push({'path': '/Business/' + ids})
    //window.location = ywzn_url.value;
  } else if (ids == 4) {
    await router.push({'path': '/Business/' + ids})
  } else if (ids == 5) {
    window.location = ddlsydcx_url.value;
  } else if (ids == 6) {
    window.location = ddyljgcx_url.value;
  } else if (ids == 7) {
    window.location = ypmlcx_url.value;
  } else if (ids == 8) {
    window.location.href = 'https://zxwb-pro.bjunicom.com.cn/yc-media/pages/web-client.jsp?channelKey=wxd9fbf0936224f0bf&keyCode=1';
  }
}

// 掌上邮寄
const onExpress = async (ids) => {
  window.location = express_url.value;
}

const onDownload = async (ids) => {
  await router.push({'path': '/Download/' + ids})
}

const onTreatment = async (ids) => {
  await router.push({'path': '/Treatment/' + ids})
}

const onContent = async (cateid, ids) => {
  await router.push({'path': '/Contents/' + cateid + '/' + ids})
}

const onCust = async (ids) => {
  await router.push({'path': '/Customer/' + ids + '/0'})
}

//自动加载
onMounted(() => {
  fetchData()
  beforeCreate()
  background.value = 'linear-gradient(to bottom,#b1d3fd, #dce7fd, #ffffff )'
	const appElement = document.getElementById('app');
    if (appElement) {
      appElement.style.background = background.value;
	}
});
</script>
<style>
.msgbox {
  width: 350px;
}
html{
	background: linear-gradient(to bottom, #b1d3fd, #dce7fd, #ffffff );
	background-repeat: no-repeat !important;
}
body {
  display: block;
  /* background-color: antiquewhite; */
  margin-top: 0px;
  margin-left: 8px;
  margin-right: 8px;
}
</style>
<style scoped>
#clear {
  clear: both
}
.top{
	width: 100%;
	margin-bottom: 2%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.top .title{
	font-size: 1.5em;
	font-weight: 700;
	margin: 5px 0;
	color: white;
	display: flex;
}
/* 屏幕宽度在330px到385px之间（包括330px但不包括401px）时使用 */
@media (max-width: 385px) and (min-width: 330px) {
    .top .title {
        font-size: 1.2em;
    }
}
 
/* 屏幕宽度小于330px时使用 */
@media (max-width: 329px) {
    .top .title {
        font-size: 1em;
    }
}
.top span{
	display: flex;
	align-items: center;
	font-size:14px;
	color: #707070;
	font-style: italic;
	text-shadow: #ACACAC;

}
.top span img{ 
	width: 14px;
	margin-left: 5%;
}
#title {
  width: 100%;
  height: 45px;
  font-size: 20px;
  text-align: center;
  line-height: 45px;
  color: #4D4D4D;
}

.is-animating img {
  width: 100%;
}

.block {
  box-shadow: 0px 0px 6px #adadad;
  /* position:absolute; */
  width: 100%;
  height: 100%;
  z-index: 0;
}

.nav {
  margin-top: 7px;
  background: #fff;
  width: 100%;
  /* min-height: 140px; */
  z-index: 10;
  border-radius: 5px;
  box-shadow: 0px 0px 6px #B9B9B9;
}
.nav .grid{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	/* align-items: center; */
	padding: 2% 0;
}
.nav .grid .type {
  width: 25%;
  text-align: center;
  margin: 3% 0;
}

.nav .type img {
  width: 40px;
  /* height: 50px; */
  line-height: 60px;
  /* margin-top:10%; */

}

.nav .type .navTitle {
  height: 100%;
  line-height: 100%;
  color: #333333;
  font-size: 14px;
  width: 80%;
  margin-left: 10%;
}

.nav .types {
  width: 50%;
  float: left;
  text-align: center;
  height: 100px;
}

.nav .types img {
  width: 60px;
  height: 60px;
  line-height: 60px;
  margin-top: 10%;

}

.nav .types .navTitle {
  /* height: 100%; */
  line-height: 100%;
  color: #333333;
  font-size: 14px;
  /* width: 80%; */
  /* margin-left: 10%; */
}

/* .Sxin {
 
  height: 255px;
} */


.box-container {
  display: flex;
  align-items: center; /* 可选，垂直居中对齐 */
  justify-content: space-around; /* 可选，水平居中对齐 */
  margin-top: 1%;
  margin-bottom: 2%;
}


.box{
  width: 48%;
  height: 100%;
  border-radius: 10px;
}
.box-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}


.Zxin {
  /* height: 170px; */
  font-size: 0;
}

#Follow {
  /* padding-top: 5%; */
  display:flex;
  align-items: center;
  font-size: 18px;
  height: 5vh;
  padding-left: 4%;
  color: #4D4D4D;
  background: #ffffff; /* 按钮背景色 */
  border: none;
  border-radius: 10px;
  box-shadow: 0px 10px 25px #f0f0f0, 0px -10px 25px #ffffff, inset 0px -5px 10px #edfeff, inset 0px 5px 10px #ffffff;
  transition: 500ms;
  animation: hueRotation 3s linear infinite
}

@keyframes hueRotation {
        to {
            filter: hue-rotate(360deg)
        }
    }

#Follow .followDian {
  font-size: 18px;
  font-weight: 600;
  margin-right: 1%;
}

.more {
  float: right;
  margin-right: 20px;
  color: #ACACAC;
  font-size: 14px;
}

::v-deep .demo-tabs {
  overflow: hidden;
  margin-top: 7px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
  text-align: center;
}

::v-deep .el-tabs__nav {
  width: 100%;
}

.contentNav {
  height: 110px;
  margin-top: 3%;
  border-bottom: 1px solid #EFEFEF;
}

.contentTitle {
  width: 95%;
  padding-bottom: 12px;
  color: #333333;
  font-size: 18px;
  word-wrap: break-word;
}

.contentBrief {
  color: #ACACAC;
  font-size: 14px;
  height: 14px;
  line-height: 14px;
  word-wrap: break-word;
}

::v-deep .el-tabs__nav-wrap::after {
  background-color: transparent;
}

::v-deep .el-tabs__item {
  text-align: center;
  width: 33%;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.el-row {
  width: 94%;
  margin-left: 3%;
  margin-bottom: 5px;
}

#Treatcontent #clear {
  width: 80%;
  list-style-type: none;
  min-height: 110px;
  line-height: 25px;
  box-shadow: 0px 0px 6px #D3DCE6;
  border-radius: 10px;
  padding: 0px;
  margin-top: 10px;
}

#Treatcontent #clear .contentTitle {
  display: inline-block;
  width: 90%;
  font-size: 16px;
  color: #333333;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
}

#Treatcontent #clear .contentBrief {
  display: inline-block;
  width: 90%;
  font-size: 16px;
  color: #333333;
  padding-left: 10px;
}
</style>
