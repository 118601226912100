<template>
  <div class="body-bg">
    <el-affix>
      <div id="title">
        <span
          id="back"
          @click="onHome()"
          style="vertical-align: top; margin-top: -2px; margin-left: 10px"
          >‹</span
        >
        <div style="margin-right: 20px;font-size: 25px;">{{ title }}</div>
      </div>
    </el-affix>

    <div id="Busicontent" style="overflow-y: hidden">
      <el-row
        v-infinite-scroll="load"
        infinite-scroll-distance="10"
        infinite-scroll-immediate="true"
        style="overflow: auto"
      >
        <el-col
          :span="24"
          id="clear"
          v-for="item in tableData"
          :key="item.index"
          @click="onContent(item.id, item.brief)"
        >
          <div
            class="contentTitle"
          >
            <span style="font-size: 22px;">{{ item.brief }}</span>
          </div>
        </el-col>
      </el-row>
      <div class="loading" v-if="loading">
        <span id="load-text">{{ loadText }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineComponent, onMounted, ref, watch, reactive } from "vue";
import * as api from "../api/loginHttp";
import { ElMessage, ElNotification } from "element-plus";
import { Calendar, Search } from "@element-plus/icons-vue";
import { useRoute, useRouter } from "vue-router";
import http, { baseUrl } from "../utils/http";
const route = useRoute();
const router = useRouter();
const cateid = ref();
const formInline = reactive({
  user: "",
  region: "",
});
const beforeCreate = async () => {
  document
    .querySelector("body")
    .setAttribute("style", "background-color:#EFEFEF;");
};
const title = ref();
const type = ref();
const keyword = ref();
const key = ref();
const contents = ref({});
const loadText = ref("正在加载...");
const loading = ref(false);
const tableData = ref([]);
const pages = ref(0);
const limit = ref(20);
const totalPages = ref();

const fetchData = async () => {
  cateid.value = route.params.cateid;
  type.value = route.params.type;
  key.value = route.params.key;
  if (route.params.key && route.params.key != 'undefined') {
    title.value = route.params.key;
  } else {
    http
      .get("api/news/getCateTitle/cateid" + "/" + cateid.value)
      .then((res) => {
        if (res.data.code == 200) {
          title.value = res.data.data;
        } else {
          ElNotification({
            title: "Error",
            message: "获取数据错误",
            type: "error",
          });
          return false;
        }
      });
  }
};
const onHome = async () => {
  await router.push({ path: "/big/Home"});
  // await router.push({ path: "/big/Customer/" + cateid.value + '/1' });
};

const onDefault = async (isReset, addTo = 0, Botton = 0) => {
  if (isReset == 1) {
    keyword.value = "";
  }

  if (Botton == 1) {
    pages.value = 0;
    tableData.value = [];
  }

  let params = {
    cateid: cateid.value,
    brief_type: type.value,
    pages: pages.value,
    limit: limit.value,
  };

  if (keyword.value != "") {
    params.keyword = keyword.value;
  }

  const data = await api.getNewList(params);
  let len = data.data.data.contents.length;
  if (data && len == 0 && !len) {
    loadText.value = "暂无更多数据";
    loading.value = true;
  } else if (data && len < 20) {
    loadText.value = "暂无更多数据";
    loading.value = true;
  } else {
    loading.value = false;
  }
  if (len > 0) {
    pages.value = pages.value + 20; //页数+10
  }
  tableData.value = tableData.value.concat(data.data.data.contents); //因为每次后端返回的都是数组，所以这边把数组拼接到一起
  totalPages.value = data.data.data.totalPages;
};

const onContent = async (ids, title) => {
  await router.push({
    path:
      "/big/Customerdetail/" +
      cateid.value +
      "/" +
      type.value +
      "/" +
      key.value +
      "/" +
      ids +
      "/" +
      title,
  });
};

const load = () => {
  if (loading.value == false) {
    setTimeout(() => {
      //滑到底部时进行加载
      //loading.value = true
      onDefault(0, 1);
    }, 1000);
  }
};

//自动加载
onMounted(() => {
  fetchData();
  beforeCreate();
  const appElement = document.getElementById('app');
  if (appElement) {
    appElement.style.background = 'none';
  }
  document.querySelector('html').setAttribute('style', 'background: none')
});
</script>

<style scoped>
#clear {
  clear: both;
}
.body-bg {
  /* background: #EFEFEF; */
  min-height: 720px;
}
#search {
  width: 98%;
  height: 40px;
  line-height: 40px;
  background: #fff;
  border-radius: 10px;
  text-align: center;
  margin-left: 1%;
  margin-top: 4%;
}

.elInput {
  width: 100%;
  text-align: center;
  font-size: 20px;
}
input::-ms-input-placeholder {
  text-align: center;
}
input::-webkit-input-placeholder {
  text-align: center;
}

.el-row {
  width: 94%;
  margin-left: 3%;
}

#Busicontent #clear {
  width: 90%;
		list-style-type:none;
		min-height: 80px;
		line-height: 25px;
		background: #fff;
		border-radius: 10px;
		padding: 0px;
		margin-top: 10px;
}
#Busicontent .contentTitle {
  display: inline-block;
  width: 96%;

  font-size: 18px;
  color: #333333;
  /*padding-top: 10px;*/
  padding-left: 10px;
  margin-bottom: 3%;
}
#Busicontent .contentBrief {
  display: inline-block;
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 10px;
  color: #b9b9b9;
  /*height: 40px;*/
  padding-left: 10px;
}
.loading {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
</style>
