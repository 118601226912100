<template>
  <div class="body-bg">
    <el-affix>
      <div id="title">
        <span
          id="back"
          @click="onHome()"
          style="vertical-align: top; margin-top: -2px; margin-left: 10px"
          >‹</span
        >
        <div style="margin-right: 20px; font-size: 25px">{{ title }}</div>
      </div>
    </el-affix>
    <div id="msgs">
      <div class="pnl-list" v-for="msg in robot.relation">
        <div class="chat-sender" v-if="msg.type ? false : true">
          <div><img src="../assets/img/rootes.png" /></div>
          <div style="font-size: 22px">智能客服</div>
          <div>
            <span
              class="messageTitle"
              style="width: 96%"
              v-html="msg.msg"
            ></span>
          </div>
        </div>
        <!-- Right -->
        <div class="chat-receiver" v-if="msg.type ? true : false">
          <div><img src="../assets/img/man.png" /></div>
          <div>用户</div>
          <div>
            <div class="chat-right_triangle"></div>
            <span class="messageTitle" v-html="msg.msg"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_guide">
      <el-input
        v-model="input"
        name=""
        placeholder="请输入咨询内容"
        class="elInput"
      />
      <el-button type="primary" style="margin-left: 1%" @click="onSubmit" round
        >发送</el-button
      >
    </div>
  </div>

  <el-dialog
    v-model="dialogTableVisible"
    :title="titles"
    width="80%"
    class="my-info-dialog"
    top="5vh"
  >
    <span v-html="contentsValue"></span>
  </el-dialog>
</template>

<script setup>
const dialogTableVisible = ref(false);
const dialogFormVisible = ref(false);
const formLabelWidth = "80%";

const form = reactive({
  name: "",
  region: "",
  date1: "",
  date2: "",
  delivery: false,
  type: [],
  resource: "",
  desc: "",
});

const titles = ref();
const contentsValue = ref();
const gridData = [
  {
    date: "2016-05-02",
    name: "John Smith",
    address: "No.1518,  Jinshajiang Road, Putuo District",
  },
];

import {
  defineComponent,
  onMounted,
  ref,
  watch,
  reactive,
  nextTick,
} from "vue";
import * as api from "../api/loginHttp";
import { ElMessage, ElNotification } from "element-plus";
import { Calendar, Search } from "@element-plus/icons-vue";
import http, { baseUrl } from "../utils/http";
import { useRoute, useRouter } from "vue-router";
const route = useRoute();
const router = useRouter();
const input = ref();
const title = ref();
const cateid = ref();
const oneMessage = ref();
const page = ref();
const lastpage = ref();
const beforeCreate = async () => {
  //document.querySelector('body').setAttribute('style', 'background-color:#fffcf5;')
};
const robot = reactive({
  relation: [],
});

const reContents = {};

const onSubmit = async () => {
  let msg = {
    msg: input.value,
    type: 1,
  };
  robot.relation = robot.relation.concat(msg);
  if (oneMessage.value == undefined) {
    oneMessage.value = 0;
  }
  if (oneMessage.value === undefined) {
    var numReg = /^[0-9]*$/;
    var numRe = new RegExp(numReg);
    if (!numRe.test(input.value)) {
      ElMessage({
        title: "Error",
        message: "请输入数字选择分类",
        type: "error",
        Style: "min-width:300px;margin-top:60%",
      });
      input.value = "";
      return false;
    }
    http.get("api/news/getCustomerVal/id/" + input.value).then((res) => {
      if (res.data.code == 201) {
        ElMessage({
          title: "Error",
          message: "请输入正确分类编号",
          type: "error",
          Style: "min-width:300px;margin-top:60%",
        });
        input.value = "";
        return false;
      }

      oneMessage.value = input.value;
      let robotReturn = "";
      robotReturn = "请输入此类相关问题";
      let robotmsg = {
        msg: robotReturn,
        type: 0,
      };
      robot.relation = robot.relation.concat(robotmsg);
    });
  } else {
    let params = {
      brief_type: oneMessage.value,
      keyword: input.value,
    };
    const res = await api.getCustomerList(params);
    let oneRobotMsg = {
      msg: "",
      type: 0,
    };
    reContents.value = res.data.data.data;
    let RobotMsg = "";
    if (res.data.data.data.length > 0) {
      RobotMsg += "<p>为您查询到如下内容，请点击具体问题进行查阅。</p>";
    } else {
      if (oneMessage.value == 0) {
        RobotMsg +=
          "<p>您好，请您输入咨询的问题的关键词，如：生育津贴、手工报销、参保.....您也可以在工作日拨打服务热线81296694进行咨询。</p>";
      } else {
        RobotMsg +=
          "<p>您好，请您输入咨询的问题的关键词，如：生育津贴、手工报销、参保.....您也可以在工作日拨打服务热线81296694进行咨询。</p>";
      }
    }

    for (let i = 0; i < res.data.data.data.length; i++) {
      RobotMsg +=
        '<p style="width:90%; display:block;" ><a style="color:#000;" onclick="onDialogTableVisible(\'' +
        i +
        "')\">" +
        (i + 1) +
        "、" +
        res.data.data.data[i].brief +
        "</a></p>";
    }
    if (res.data.data.count > 10) {
      RobotMsg +=
        '<p style="width:90%; display:block;font-size: 22px;" ><a style="color:blue;" onclick="onmore(\'' +
        params.brief_type +
        "','" +
        params.keyword +
        "')\">点击此处查看更多</a></p>";
    }
    oneRobotMsg.msg = RobotMsg;
    robot.relation = robot.relation.concat(oneRobotMsg);
  }
  setTimeout(() => {
    //设置延迟执行
    scrollToBottom();
    input.value = "";
  }, 500);
};

window.onmore = function (type, keyword) {
  // console.log(type, keyword);
  router.push({
    path: "/big/CustomerList/" + cateid.value + "/" + type + "/" + keyword,
  });
};

const scrollToBottom = async () => {
  nextTick(() => {
    let msgsss = document.getElementById("msgs"); // 获取对象
    document.documentElement.scrollTop += msgsss.scrollHeight;
    document.body.scrollTop += msgsss.scrollHeight;
  });
};

window.onDialogTableVisible = function (id) {
  //重新答题

  let oneRobotMsg = {
    msg: "",
    type: 0,
  };
  let RobotMsg =
    "<p>" +
    reContents.value[id].brief +
    "</p><p>" +
    reContents.value[id].contents +
    "</p>";
  // for(let i = 0; i < res.data.data.length; i++) {
  // 	RobotMsg += '<p onclick="onSetMessage(\''+res.data.data[i].id+'\')">'+res.data.data[i].id+'、'+res.data.data[i].name+'</p>'
  // }
  oneRobotMsg.msg = RobotMsg;
  robot.relation = robot.relation.concat(oneRobotMsg);

  setTimeout(() => {
    //设置延迟执行
    scrollToBottom();
    input.value = "";
  }, 500);

  // 	titles.value = reContents.value[id].brief
  // 	contentsValue.value = reContents.value[id].contents
  // 	dialogTableVisible.value = true;
};

const fetchData = async () => {
  cateid.value = route.params.cateid;
  http.get("api/news/getCateTitle/cateid" + "/" + cateid.value).then((res) => {
    if (res.data.code == 200) {
      title.value = res.data.data;
    } else {
      ElMessage({
        title: "Error",
        message: "获取数据错误",
        type: "error",
        Style: "min-width:300px;margin-top:60%",
      });
      return false;
    }
  });
  http.get("api/news/getCustomerCate/page/1").then((res) => {
    if (res.data.code == 200) {
      page.value = res.data.data.current_page;
      lastpage.value = res.data.data.last_page;
      let oneRobotMsg = {
        msg: "",
        type: 0,
      };
      let RobotMsg =
        "<p>您好！</p><p>请选择以下问题分类后进行分类下的问题查询，或在底部输入框中直接输入您想咨询的问题的关键词，例如：生育津贴;手工报销;参保…</p>";
      for (let i = 0; i < res.data.data.data.length; i++) {
        if (i < 10) {
          RobotMsg +=
            "<p onclick=\"onSetMessage('" +
            res.data.data.data[i].id +
            "')\">" +
            res.data.data.data[i].id +
            '、<span style="color:blue;text-decoration:underline;">' +
            res.data.data.data[i].name +
            "</span></p>";
        }
      }
      if (res.data.data.total > 10) {
        RobotMsg +=
          '<p style="width:90%; display:block;" ><a style="color:blue;" onclick="onGetPage()">查看其他</a></p>';
      }

      oneRobotMsg.msg = RobotMsg;
      robot.relation = robot.relation.concat(oneRobotMsg);
    } else {
      ElMessage({
        title: "Error",
        message: "获取数据错误",
        type: "error",
        Style: "min-width:300px;margin-top:60%",
      });
      return false;
    }
  });
};

window.onGetPage = function () {
  if (page.value == lastpage.value) {
    page.value = 1;
  } else {
    page.value = parseInt(page.value) + parseInt(1);
  }

  http.get("api/news/getCustomerCate/page/" + page.value).then((res) => {
    if (res.data.code == 200) {
      let oneRobotMsg = {
        msg: "",
        type: 0,
      };
      let RobotMsg =
        "<p>您好！</p><p>请选择以下问题分类后进行分类下的问题查询，或在底部输入框中直接输入您想咨询的问题的关键词，例如：生育津贴;手工报销;参保…</p>";
      for (let i = 0; i < res.data.data.data.length; i++) {
        RobotMsg +=
          "<p onclick=\"onSetMessage('" +
          res.data.data.data[i].id +
          "')\">" +
          res.data.data.data[i].id +
          '、<span style="color:blue;text-decoration:underline;">' +
          res.data.data.data[i].name +
          "</span></p>";
      }
      if (res.data.data.total > 10) {
        RobotMsg +=
          '<p style="width:90%; display:block;" ><a style="color:blue;" onclick="onGetPage()">查看其他</a></p>';
      }

      oneRobotMsg.msg = RobotMsg;
      // console.log(oneRobotMsg);
      robot.relation = [];
      robot.relation = robot.relation.concat(oneRobotMsg);
    } else {
      ElMessage({
        title: "Error",
        message: "获取数据错误",
        type: "error",
        Style: "min-width:300px;margin-top:60%",
      });
      return false;
    }
  });
};

const onGetCustomer = async (id) => {};

const onHome = async () => {
  await router.push({ path: "/big/Home/" });
};

window.onSetMessage = function (id) {
  //重新答题
  oneMessage.value = id;
  let msg = {
    msg: oneMessage.value,
    type: 1,
  };
  robot.relation = robot.relation.concat(msg);
  if (oneMessage.value === undefined) {
    var numReg = /^[0-9]*$/;
    var numRe = new RegExp(numReg);
    if (!numRe.test(input.value)) {
      ElMessage({
        title: "Error",
        message: "请输入数字选择分类",
        type: "error",
        Style: "min-width:300px;margin-top:60%",
      });
      return false;
    }

    oneMessage.value = input.value;
    let robotReturn = "";
    robotReturn = "请输入您想查询的问题";
    let robotmsg = {
      msg: robotReturn,
      type: 0,
    };
    robot.relation = robot.relation.concat(robotmsg);
    input.value = "";
  } else {
    let params = {
      brief_type: oneMessage.value,
      keyword: input.value,
    };
    http
      .get(
        "api/News/getCustomerList/brief_type" +
          "/" +
          oneMessage.value +
          "/keyword/" +
          input.value
      )
      .then((res) => {
        // console.log(res);
        let oneRobotMsg = {
          msg: "",
          type: 0,
        };
        reContents.value = res.data.data.data;
        let RobotMsg = "";
        if (res.data.data.data.length > 0) {
          RobotMsg += "<p>为您查询到如下内容，请点击具体问题进行查阅。</p>";
        } else {
          RobotMsg +=
            '<p style="font-size: 22px;">未搜索到相关内容，请重新输入</p>';
        }

        for (let i = 0; i < res.data.data.data.length; i++) {
          RobotMsg +=
            '<p style="width:90%; display:block;font-size: 22px;" ><a style="color:#000;" onclick="onDialogTableVisible(\'' +
            i +
            "')\">" +
            (i + 1) +
            "、" +
            res.data.data.data[i].brief +
            "</a></p>";
        }
        if (res.data.data.count > 10) {
          RobotMsg +=
            '<p style="width:90%; display:block;font-size: 22px;" ><a style="color:blue;" onclick="onmore(\'' +
            oneMessage.value +
            "','" +
            input.value +
            "')\">点击此处查看更多</a></p>";
        }
        oneRobotMsg.msg = RobotMsg;
        // console.log(oneRobotMsg.msg);
        robot.relation = robot.relation.concat(oneRobotMsg);
        input.value = "";
      });
  }
  setTimeout(() => {
    //设置延迟执行
    scrollToBottom();
    input.value = "";
  }, 500);
};

const onReset = async () => {
  location.reload();
};

onMounted(() => {
  fetchData();
  const appElement = document.getElementById('app');
  if (appElement) {
    appElement.style.background = 'none';
  }
  document.querySelector('html').setAttribute('style', 'background: none')
});
</script>

<style scoped>
.body-bg {
  /* background: #fff; */
  margin-bottom: 11%;
  min-height: 400px;
  padding-bottom: 10px;
}
.elInput {
  max-width: 75%;
}
::v-deep.el-button + .el-button {
  margin-left: 0px;
  border-radius: 25px;
}
::v-deep .el-input__inner {
  background: #eee;
  border-radius: 25px;
}
.messageTitle {
  display: inline-block;
  border-radius: 10px;
  /*padding-left: 10px;*/
  /*margin-left: 15px;*/
  font-size: 20px;
}
.chat-sender {
  clear: both;
  font-size: 80%;
  padding-top: 10px;
}
.chat-sender div:nth-of-type(1) {
  float: left;
}
.chat-sender div:nth-of-type(3) {
  background-color: white;
  /*float: left;*/
  margin: 0 50px 10px 50px;
  padding: 10px 10px 10px 10px;
  border-radius: 7px;
  /*text-indent: -12px;*/
}
.chat-sender div:nth-of-type(3) {
  background-color: #eee;
  /*float: left;*/
  margin: 0 50px 10px 50px;
  padding: 10px 10px 10px 10px;
  border-radius: 7px;
  /* text-indent: -12px; */
}

.chat-receiver {
  clear: both;
  font-size: 16px;
}
.chat-receiver div:nth-of-type(1) {
  float: right;
}
.chat-receiver div:nth-of-type(2) {
  margin: 0px 50px 2px 50px;
  padding: 0px;
  color: #848484;
  font-size: 70%;
  text-align: right;
}
.chat-receiver div:nth-of-type(3) {
  float: right;
  background-color: #b2e281;
  /* margin: 0px 50px 10px 50px; */
  padding: 10px 10px 10px 10px;
  border-radius: 7px;
}

.chat-receiver div:first-child img,
.chat-sender div:first-child img {
  width: 40px;
  height: 40px;
  margin: 0 5px;
  /*border-radius: 10%;*/
}

.chat-left_triangle {
  height: 0px;
  width: 0px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent white transparent transparent;
  position: relative;
  left: -22px;
  top: 3px;
}
.chat-right_triangle {
  height: 0px;
  width: 0px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent transparent #b2e281;
  position: relative;
  right: -22px;
  top: 3px;
}

.chat-notice {
  clear: both;
  font-size: 70%;
  color: white;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
.chat-notice span {
  background-color: #cecece;
  line-height: 25px;
  border-radius: 5px;
  padding: 5px 10px;
}

.footer_guide {
  margin-left: 2%;
  background: #fff;
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  bottom: 10px;
  width: 100%;
  height: 40px;
  display: flex;
}
.guide_item {
  display: flex;
  flex: 1;
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin: 5px;
}
.on {
}
span {
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: 2px;
}
.iconfont {
  font-size: 22px;
}
</style>

<style scoped>
.el-button--text {
  margin-right: 15px;
}
.el-select {
  width: 300px;
}
.el-input {
  /* width: 300px; */
}
.dialog-footer button:first-child {
  margin-right: 10px;
}
::v-deep .el-dialog {
  width: 80%;
}

::v-deep .el-message {
  min-width: 300px;
}
</style>
<style >
.el-message {
  min-width: 300px;
}
.my-info-dialog {
  --el-dialog-width: 80%;
  width: 80%;
  height: 80%;
  margin-top: 1%;
}
.el-message {
  --el-message-min-width: 300px;
}
.elError {
  --el-message-min-width: 300px;
  min-width: 100px;
  color: red;
}
</style>
