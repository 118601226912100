<template>
		<!-- <div class="body-bg"> -->
			<el-affix >
				<div id="title"><span id="back" @click="onHome()" style="vertical-align: top;margin-top: -2px;margin-left: 10px;">‹</span><div style="margin-right: 20px;">{{ title }}</div></div>
			</el-affix>
			<div id="subTitle">{{contentsTitle}}</div>
			<div id="contents"  v-html="contentsValue">
			</div>
		<!-- </div> -->
</template>

<script setup>
	import {defineComponent, onMounted, ref, watch, reactive} from 'vue'
	import * as api from "../api/loginHttp";
	import {ElMessage, ElNotification} from "element-plus";	
	import { Calendar, Search } from '@element-plus/icons-vue'
	import {useRoute, useRouter} from "vue-router"
	import http, {baseUrl} from "../utils/http";
	const route = useRoute()
	const router = useRouter()
	const cateid = ref()
    const type = ref()
    const key = ref()
	const title = ref()
	const id = ref()
	const contentsTitle = ref()
	const contentsValue = ref()
	const fetchData = async () => {
        // console.log(route.params);return;
		cateid.value = route.params.cateid
        type.value = route.params.type
        key.value = route.params.key
		id.value = route.params.id
        title.value = route.params.title
		// http.get('api/news/getCateTitle/cateid' + "/" + cateid.value).then((res)=>{
		// 	if(res.data.code == 200) {
		// 		title.value = res.data.data
		// 	} else {
		// 		ElNotification({
		// 		    title: 'Error',
		// 		    message: '获取数据错误',
		// 		    type: 'error',
		// 		  })
		// 		return false;
		// 	}
		// })
		http.get('api/news/contents/id' + "/" + id.value).then((res)=>{
		
			if(res.data.code == 200) {
				contentsTitle.value = res.data.data.title
				contentsValue.value = res.data.data.contents
			} else {
				ElNotification({
				    title: 'Error',
				    message: '获取数据错误',
				    type: 'error',
				  })
				return false;
			}
		
		})
	}
	
	const beforeCreate = async () => {
		document.querySelector('body').setAttribute('style', 'background-color:#fff;')
	}
	const onHome = async () =>{

		await router.push({'path': '/big/Customerlist/' + cateid.value + '/' + type.value + '/' + key.value})
		
		
	}
	//自动加载
	onMounted(() => {
	  fetchData()
	  beforeCreate()
	  const appElement = document.getElementById('app');
	  if (appElement) {
	    appElement.style.background = 'none';
	  }
	  document.querySelector('html').setAttribute('style', 'background: none')
	})
</script>

<style>
	.body-bg {
	    /* background: #EFEFEF; */
	    min-height: 720px;
	}
	
	#subTitle {
		color:#000;
		text-align: center;
		min-height: 5px;
		line-height: 30px;
		font-size: 22px;
		background-color: #fff;
	}
	#back {
		float: left;
		font-size: 30px;
	}
	#contents {
		background-color: #fff;
        font-size: 25px;
	}
	#contents img{
		width: 100%;
	}
</style>
